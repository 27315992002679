<template>
    <div class="comment-area" v-if="total_comments > 0">
        <h2><i class="fa fa-comments"></i> Comments ({{ total_comments }})</h2>
        <ol class="comment-list">
            <li class="comment even thread-even depth-1" v-for="(item, indx) of commentData" :key="indx">
                <article class="comment-body">                                               
                    <div class="comment-author-img">
                        <span>{{ item.user.substring(0, 1).toUpperCase() }}</span>
                    </div>
                                
                    <div class="comment-content">
                        <h3>
                            <a href="javascript:void(0);">{{ item.user }}</a> 
                            <span><i class="icon-material-outline-access-time"></i> {{ item.created_at }}</span>
                        </h3>
                        <div v-html="item.comments"></div>
                    </div>
                </article>
            </li>
        </ol>                       
    </div>
</template>

<script>
export default {
    props: {
        comments: Array,
        totalComments: Number
    },
    data() {
        return {
            commentData: [],
            total_comments: 0
        }
    },
    created: function() {
        this.total_comments = this.$props.totalComments
        this.commentData = this.$props.comments
    },
    watch: {
        "$props.totalComments"() {
            this.total_comments = this.$props.totalComments
            this.commentData = this.$props.comments
        }
    }
}
</script>