<template>
    <div class="col-sm-3 forum_sidebar">
        <div class="sidebar_box">
            <h3>{{ pageContent('categories') }}</h3>
            <ul class="catlist">
                <li :class="!activeCategory ? 'active' : ''">
                    <router-link to="/forum/">{{ pageContent('all_courses') }}</router-link>
                </li>
                <li :class="(activeCategory == item.id) ? 'active' : ''" v-for="(item, indx) of categoryData" :key="indx">
                    <router-link :to="`/forum/${item.id}/category`">{{ item.category }}</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Translation from '../../../public/translation.json'

export default {
    props:{
        categoryid: Number
    },
    data(){
        return {
            categoryData: [],
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            activeCategory: 0 
        }
    },
    methods: {
        pageContent(field) {
            return Translation.content[this.defaultLang][field]
        },
        getCategory() {
            this.$http.get(`forum/category`).then(res=> {
                if(res.data.status === true) {
                    this.categoryData = res.data.data
                }
            })
            .catch(err => console.log(err))
        }
    },
    created: function() {
        this.getCategory()
        this.activeCategory = this.$route.params.categoryid || this.$props.categoryid
    },
    watch: {
        "$route.params.categoryid"(){
            this.activeCategory = this.$route.params.categoryid
        },
        "$props.categoryid"(){
            this.activeCategory = this.$props.categoryid
        }
    }
}
</script>