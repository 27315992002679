<template>
    <div class="inner_banner">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="full">
                        <h3>{{ pageTitle }}</h3>
                        <nav id="breadcrumbs" v-if="breadCrumb.length > 0">
                            <ul>
                                <li v-for="(itm, indx) of breadCrumb" :key="indx">
                                    <router-link :to="itm.path"> {{ itm.title }} </router-link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>              
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        title: String,
        bredcrumb: Array
    },
    data(){
        return {
            pageTitle: 'Home',
            breadCrumb: []
        }
    },
    created: function(){
        this.pageTitle = this.$props.title
        this.breadCrumb = this.$props.bredcrumb
    },
    watch: {
        'title'(){
            this.pageTitle = this.$props.title
        },
        'bredcrumb'() {
            this.breadCrumb = this.$props.bredcrumb
        }
    }
}
</script>
