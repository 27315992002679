<template>
    <section>
        <InsideHeader :title="pageData.page_heading" :bredcrumb="breadCrumb" />  
        <div class="section fofum_wrap forum_details">
            <div class="container">
                <div class="row">
                    <div class="col-sm-9 forum_left forum_details">
                        <div class="entry-header">
                            <h2 class="entry-title">{{ forumData.title }}</h2>
                            <p class="posted-by">by <a href="#">{{ forumData.author }}</a> 
                                <i class="icon-material-outline-access-time"></i> {{ forumData.post_date }}
                                <a title="Like" class="like-anchor " href="javascript:void(0)" @click="topicLike" :class="activeReaction == 1 ? 'marked' : ''">
                                    <i class="icon-material-outline-thumb-up"></i> {{ postReactions.total_likes ? postReactions.total_likes : 0 }} 
                                </a>
                                <a title="Disike" class="dislike-anchor " href="javascript:void(0)" @click="topicDislike" :class="activeReaction == 2 ? 'marked' : ''">
                                    <i class="icon-material-outline-thumb-down"></i> {{ postReactions.total_dislikes ? postReactions.total_dislikes : 0 }}  
                                </a>
                            </p>
                        </div>
                        <div class="entry-summary" v-html="forumData.content"></div>
                        <!-- <div class="post-content-bottom">
                            <span class="post-share">Share:</span> 
                            <div class="share_icons">
                                <a href=""><i class="fa fa-facebook"></i></a> 
                                <a href=""><i class="fa fa-twitter"></i></a> 
                                <a href=""><i class="fa fa-google-plus"></i></a> 
                                <a href=""><i class="fa fa-pinterest"></i></a>
                            </div>
                        </div> -->

                        <div id="comments" class="comments-area">
                            <Comments :totalComments="totalComments" :comments="comments" />
                            <CommentForm v-if="storeToken" />
                            <h3 class="auth-message" v-else>
                                {{ addComment }}
                                <router-link to="/account/login" class="link">{{ clickHereToLogin }}</router-link>
                            </h3>
                        </div>
                    </div>
                    <Category :categoryid="forumData.category_id"/>
                </div>        
            </div>
        </div>
    </section>
</template>

<script>
import InsideHeader from "@/components/InsideHeader.vue";
import Category from './Category'
import CommentForm from './CommentForm'
import Comments from './Comments'
import Translation from '../../../public/translation.json'

export default {
    components: {Category, InsideHeader, CommentForm, Comments},
    data(){
        return {
            breadcrumb: [],
            forumData: [],
            pageData: [],
            forumId: this.$route.params.id,
            storeToken: this.$store.getters.storeToken || '',
            comments: [],
            totalComments: 0,
            postReactions: [],
            activeReaction: '',
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },    
    computed: {
        breadCrumb(){
            return this.breadcrumb = [
                {title: this.pageContent('home'), path:'/'},
                {title: this.pageContent('forum'), path:'#'}
            ]
        },
        addComment(){            
            return Translation.content[this.defaultLang]['do_you_want_to_add_your_comment'];
        },
        clickHereToLogin() {
            return Translation.content[this.defaultLang]['click_here_to_login'];
        }
    },
    methods: {
         pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        topicLike(){
            if(this.storeToken === null || this.storeToken == '') {
                return this.unauthorized()
            }
            let params = {
                topic_id: this.forumId,
                reactions: 1
            }
            this.$http.post(`account/user/post-reaction`, params).then(res=> {
                if(res.data.status === true) {
                    this.getTopics()
                }
            })
            .catch(err => console.log(err))
        },
        topicDislike() {
            if(this.storeToken === null || this.storeToken == '') {
                return this.unauthorized()
            }
            let params = {
                topic_id: this.forumId,
                reactions: 2
            }
            this.$http.post(`account/user/post-reaction`, params).then(res=> {
                if(res.data.status === true) {
                    this.getTopics()
                }
            })
            .catch(err => console.log(err))
        },
        getPageData() {
            let slug = 'forum'
            this.$store.dispatch('getPageData', slug).then(res=>{
               if(res.data.status === true) {
                    this.pageData = res.data.data
                }
            })
            .catch(err => console.log(err))
        },
        getTopics() {
            let headers = {},
                endPoint = `forum/${this.forumId}/forum-details`;
            if(this.storeToken) {
                headers = {'headers': { 'Authorization': 'Bearer '+ this.storeToken }}
                endPoint = `account/user/${this.forumId}/forum-details`
            }
            
            this.$http.get(endPoint, headers).then(res=> {
                if(res.data.status === true) {
                    this.forumData = res.data.data
                    this.totalComments = this.forumData.total_comments
                    this.comments = this.forumData.comments
                    this.postReactions = this.forumData.react_data
                    this.activeReaction = this.postReactions.user_reations.react
                }
            })
            .catch(err => console.log(err))
        },
        unauthorized(){
            this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'You are not authorized! Please login.',
            });
            return;
        }
    },
    created: function(){
        this.getPageData()
        this.getTopics()
    },
    watch: {
        
    }
}
</script>

<style scoped>
.link {
    color: #6253f1;
}
</style>